<template>
    <div class="c-alert" :class="computedClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: ['type'],
    computed: {
        computedClass () {
            if (!this.type) {
                return ''
            }

            return `c-alert__${this.type}`
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .c-alert {
        padding: 8px 16px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        text-align: center;
        border-radius: 0.5rem;

        &__primary {
            background-color: black;
            color: white;
        }

        &__danger {
            background-color: #f8d7da;
            color: #721c24;
        }

        &__success {
            background-color: #c3e6cb;
            color: #155724;
        }

        &__info {
            background-color: #cce5ff;
            color: #004085;
        }

        &__accent {
            background-color: $accent-color;
            color: black;
        }
    }
</style>
