<template>
    <div v-if="changes.productNotPublished">
        <p>
            <strong>This product hasn't been published yet, so there are no changes to be displayed.</strong>
        </p>
    </div>
    <div v-else-if="changes.everySkuChanged">
        <p>
            <strong>Note: one or more attributes were added or removed. That means that every SKU will be affected.</strong>
        </p>
        <p>
            Number of added SKU's: <strong>{{ changes.addedSkus }}</strong> <br/>
            Number of removed SKU's: <strong>{{ changes.removedSkus }}</strong> <br/>
            Added attributes: <strong>{{ changes.addedAttributes.join(', ') }}</strong> <br/>
            Removed attributes: <strong>{{ changes.removedAttributes.join(', ') }}</strong>
        </p>
    </div>
    <div v-else>
        <p>
            <strong>No added or removed attributes. Check the affected SKU's below.</strong>
        </p>
        <p>
            Number of added SKU's: <strong>{{ changes.addedSkus.length }}</strong> <br/>
            Number of removed SKU's: <strong>{{ changes.removedSkus.length }}</strong> <br/>
        </p>

        <div class="table-responsive mt-3" v-if="changes.addedSkus.length">
            <table class="margin-table w-100">
                <thead>
                    <tr>
                        <th class="p-2">
                            <i class="fas fa-plus-circle mr-1"></i> Added SKU's
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="addedSku in changes.addedSkus" :key="addedSku.sku">
                        <td class="p-2 text-small pointer">
                            <span @click="toggleSkuDetails(addedSku.sku)">
                                <i class="fas fa-chevron-down mr-2" v-if="!skuDetailsToggled.includes(addedSku.sku)"></i>
                                <i class="fas fa-chevron-up mr-2" v-else></i>
                                {{ addedSku.sku }}
                            </span>
                            <p class="cursor-auto" v-if="skuDetailsToggled.includes(addedSku.sku)">
                                <span v-for="attribute in addedSku.attributes" :key="attribute.attributeName">
                                    <strong>{{ attribute.attributeName }}</strong>: {{ attribute.attributeOption }}
                                    <br/>
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive mt-3" v-if="changes.removedSkus.length">
            <table class="margin-table w-100">
                <thead>
                    <tr>
                        <th class="p-2">
                            <i class="fas fa-minus-circle mr-1"></i> Removed SKU's
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="removedSku in changes.removedSkus" :key="removedSku.sku">
                        <td class="p-2 text-small pointer">
                            <span @click="toggleSkuDetails(removedSku.sku)">
                                <i class="fas fa-chevron-down mr-2" v-if="!skuDetailsToggled.includes(removedSku.sku)"></i>
                                <i class="fas fa-chevron-up mr-2" v-else></i>
                                {{ removedSku.sku }}
                            </span>
                            <p class="cursor-auto" v-if="skuDetailsToggled.includes(removedSku.sku)">
                                <span v-for="attribute in removedSku.attributes" :key="attribute.attributeName">
                                    <strong>{{ attribute.attributeName }}</strong>: {{ attribute.attributeOption }}
                                    <br/>
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupplierImportedProductChanges',
    props: {
        changes: {
            required: true,
        },
    },
    data () {
        return {
            skuDetailsToggled: [],
        }
    },
    methods: {
        toggleSkuDetails (sku) {
            if (this.skuDetailsToggled.includes(sku)) {
                this.skuDetailsToggled.splice(this.skuDetailsToggled.indexOf(sku), 1)
            } else {
                this.skuDetailsToggled.push(sku)
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .text-small {
        font-size: 12px;
    }

    .cursor-auto {
        cursor: auto;
    }
</style>
